import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import { Message, MessageBox } from 'element-ui'
import fanyi from '../utlis/language'
Vue.use(VueRouter)

const routes = [
  /* 用户页面*/
  {
    path: '/user',
    name: 'user',
    component: () => import('../views/UserCenter/index.vue'),
    meta: {
      requireAuth: true,
    },
    children: [
      // 用户-首页
      {
        path: '/user/index',
        name: 'userIndex',
        component: () =>
          import('../views/UserCenter/views/UserIndex/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Personal center',
        },
      },
      // 用户-订单页面
      {
        path: '/user/order',
        name: 'userOrder',
        component: () =>
          import('../views/UserCenter/views/userOrder/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'User order',
        },
      },

      // 用户-问题产品
      {
        path: '/user/faultyproducts',
        name: 'faultyproducts',
        component: () =>
          import('../views/UserCenter/views/issueOrder/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Faulty products',
        },
      },

      // 用户-运输中页面
      {
        path: '/user/packinglist',
        name: 'Packinglist',
        component: () =>
          import('../views/UserCenter/views/Packinglist/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Packinglist',
        },
      },
      // 用户仓库页面
      {
        path: '/user/Warehouse',
        name: 'Warehouse',
        component: () =>
          import('../views/UserCenter/views/warehouse/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Warehouse',
        },
      },

      // 用户-充值记录页面
      {
        path: '/user/paymentsummary',
        name: 'paymentsummary',
        component: () =>
          import('../views/UserCenter/views/churujinList/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Payment Summary ',
        },
      },
      // 用户-提现记录页面
      {
        path: '/user/WithdrawalRecord',
        name: 'WithdrawalRecord',
        component: () =>
          import(
            '../views/UserCenter/views/churujinList/views/WithdrawalRecord.vue'
          ),
      },
      // 用户-收藏夹产品页面
      {
        path: '/user/favoriteproduct',
        name: 'favoriteproduct',
        component: () =>
          import('../views/UserCenter/views/collection/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Favourite products',
        },
      },
      // 用户-收藏夹商店页面
      {
        path: '/user/favoriteshop',
        name: 'favoriteshop',
        component: () =>
          import('../views/UserCenter/views/favoritec/index.vue'),
        meta: {
            // 标题，标题描述
            tagtitle: 'Favorite shops',
          },
      },

      // 用户-收藏夹商品库列表页面
      {
        path: '/user/productlist',
        name: 'productlist',
        component: () =>
          import('../views/UserCenter/views/commodity/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Product lists',
        },
      },
      // 用户-登录信息页面
      {
        path: '/user/userDetail',
        name: 'userDetail',
        component: () =>
          import('../views/UserCenter/views/userDetail/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Personal information modification',
        },
      },
      // 用户-编辑地址页面
      {
        path: '/user/deliverySite',
        name: 'deliverySite',
        component: () =>
          import('../views/UserCenter/views/deliverySite/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Address management',
        },
      },
      // 提现页面
      {
        path: '/user/withdrawl',
        name: 'withdrawl',
        component: () => import('../views/UserCenter/views/WithdrawDeposit/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Withdrawal',
        },
      },

      // 用户-更改密码页面
      {
        path: '/user/changepassword', 
        name: 'changepassword',
        component: () =>
          import('../views/UserCenter/views/changepassworld/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Change password',
        },
      },
      // 用户-优惠券
      {
        path: '/user/coupon',
        name: 'coupon',
        component: () => import('../views/UserCenter/views/coupon/index.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Coupon',
        },
      },

      // 用户-通知
      {
        path: '/user/inform',
        name: 'inform',
        component: () => import('../views/UserCenter/views/inform/idnex.vue'),
        meta: {
          // 标题，标题描述
          tagtitle: 'Inform',
        },
      },
    ],
  },
  // 首页
  {
    path: '/',
    name: 'homepage',
    meta: {
      keepAlive: true,
    },
    component: () => import('../views/homepage/index.vue'),
    meta: {
      // 标题，标题描述
      tagtitle:
        'Rakumart - Your Convenient Solution for Purchasing and Importing Goods from China',
      tagdescription:
        "We will provide you with tens of thousands of suppliers to choose from ,You can choose what you need from the world's largest wholesale supply market.",
    },
  },
  // 用户-问题产品详情
  {
    path: '/issueOrderDetail',
    name: 'issueOrderDetail',
    component: () =>
      import('../views/UserCenter/views/issueOrder/views/issueOrderDetail.vue'),
  },
  // 订单页面
  {
    path: '/orderDetails',
    name: 'orderDetails',
    component: () =>
      import('../views/UserCenter/views/userOrder/views/OrderDetails.vue'),

    meta: {
      requireAuth: true,
      tagtitle: 'Order Details',
      temporary: ' Place an order',
      offer:'Awaiting quote order',
      obligation: 'Awaiting payment order',
      purchase:'Purchasing order',
      purchased: 'Purchase complete',
      warehouse: 'In stock order',
      delete:'Closed orders',
    },
  },

  // 商品详情
  {
    path: '/productDetails',
    name: 'productDetails',
    component: () => import('../views/order/ProductDetails.vue'),
    meta: {
      // 标题，标题描述
      tagtitle: '1688 link search',
    },
  },
  // ?店铺商品
  {
    path: '/shopGoods',
    name: 'shopGoods',
    component: () => import('../views/productDetail/shopGoods.vue'),
  },
  // 忘记密码页面
  {
    path: '/forgetThePassword',
    name: 'ForgetThePassword',
    component: () => import('../views/login/ForgetThePassword.vue'),
  },
  /* 注册页面*/
  {
    path: '/register',
    name: 'register',
    component: () => import('../views/login/register.vue'),
    meta: {
      tagtitle: 'Register an account on RAKUMART',
    },
  },
  
  /* 登录页面*/
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/login/login.vue'),
    meta: {
      title: 'Login your account',
    },
  },

  /* 购物车列表*/
  {
    path: '/cart',
    name: 'cart',
    component: () => import('../views/order/cart'),
    meta: {
      requireAuth: true,
      tagtitle: 'Shopping cart ',
    },
  },

  /* 订单支付*/
  {
    path: '/payment',
    name: 'payment',
    component: () => import('../views/payment/payment'),
    meta: {
      tagtitle: 'Rakumart payment interface',
    },
  },
  // paypal 支付失败

  {
    path: '/paypalPaymentError',
    name: 'paypalPaymentError',
    component: () => import('../views/paypalPaymentError/index.vue'),
    meta: {
      // tagtitle: 'Rakumart payment interface',
    },
  },
  /* 商品搜索页*/
  {
    path: '/commoditySearch',
    name: 'commoditySearch',
    component: () => import('../views/order/CommoditySearch'),
    meta: {
      title: 'Image search',
    },
  },
  /* 隐私政策*/
  {
    path: '/privacypolicy',
    name: 'privacypolicy',
    component: () =>
      import(/* webpackChunkName: "foot" */ '../views/footList/privacyPolicy'),
    meta: {
      tagtitle: 'Rakumart - Privacy Policy',
      tagdescription: 'Rakumart - Privacy Policy',
    },
  },
  /* 摄影服务*/
  {
    path: '/photography-services',
    name: 'PhotographyServices',
    component: () =>
      import(
        /* webpackChunkName: "foot" */ '../views/footList/PhotographyServices'
      ),
      meta: {
        tagtitle: 'Photography services',
      },
  },

  /* 服务条款*/
  {
    path: '/terms-of-service',
    name: 'terms-of-service',
    component: () =>
      import(/* webpackChunkName: "foot" */ '../views/footList/termsService'),
    meta: {
      tagtitle: 'Rakumart - General Terms',
      tagdescription: 'Rakumart - General Terms',
    },
  },
  /* option，附加服务*/
  {
    path: '/additionalservices',
    name: 'additionalservices',
    component: () =>
      import(/* webpackChunkName: "foot" */ '../views/footList/option'),
    meta: {
      tagtitle: 'Additional Services',
      tagdescription:
        'Rakumart offers a range of additional services to enhance your experience, including FBA labeling, product photography, ODM customization, translation, and more',
    },
  },

  /* 公司介绍*/
  {
    path: '/company-introduction',
    name: 'company-introduction',
    component: () =>
      import(/* webpackChunkName: "foot" */ '../views/footList/introduction'),
    meta: {
      tagtitle: 'Company introduction',

    },
  },
  /* 公司推荐*/
  {
    path: '/about-us',
    name: 'quienes-somos',
    component: () =>
      import(
        /* webpackChunkName: "foot" */ '../views/footList/quienes-somos.vue'
      ),
    meta: {
      tagtitle: 'Trusted agency for importing from China',
      tagdescription:
        'Streamline your China purchasing and importing process while optimizing costs. Let us handle everything for you.',
    },
  },



  // 用户指南
  {
    path: '/user-guide',
    name: 'userGuide',
    component: () => import('../views/footList/userGuide.vue'),
    meta: {
      tagtitle: 'User guide',
    },
  },
  /* 常见问题*/
  {
    path: '/faq',
    name: 'commonProblem',
    component: () =>
      import(/* webpackChunkName: "foot" */ '../views/footList/CommonProblem'),
    meta: {
      tagtitle: 'FAQ',
    },
  },

  /*佣金*/
  {
    path: '/commission',
    name: 'commission',
    component: () =>
      import(
        /* webpackChunkName: "committee" */ '../views/footList/Committee.vue'
      ),
    meta: {
      tagtitle: 'Commission - Transparent rates, abundant value',
    },
  },
  // 视频维护
  {
    path: '/tutorialvideo',
    name: 'tutorialvideo',
    component: () => import('../views/footList/VideoMaintenance.vue'),
    meta: {
      tagtitle: 'Tutorial video-Website operation video',
    },
  },
   // 讲师宣传
   {
    path: '/promoter',
    name: 'promoter',
     component: () => import('../views/footList/LecturerPromotion.vue'),
     meta: {
      tagtitle: 'Become a promoter to earn commissions!',
    },
  },
  // /* 国际运费*/
  {
    path: '/internationalfreight',
    name: 'internationalfreight',
    component: () =>
      import(
        /* webpackChunkName: "foot" */ '../views/footList/international_freight.vue'
      ),
    meta: {
      tagtitle:
        'International Freight - Let us handle everything for you',
      tagdescription:
        "Transform the way you shop and import from China with Rakumart's comprehensive resources and services.",
    },
  },

  /*配送单详情*/
  {
    path: '/deliveryDetails',
    name: 'deliveryDetails',
    component: () =>
      import(
        '../views/UserCenter/views/deliveryList/views/DeliveryDetails/index.vue'
      ),
      meta: {
        tagtitle: 'Rakumart-deliveryDetails',
        Inthequotation: 'Awaiting quote packinglist',//报价中
        obligation: 'Awaiting payment packinglist',//待付款
        tobeshipped: 'Awaiting shipping  packinglist',//待发货配送单 
        Shipped: "Packinglist from China to US",//已发货配送单
        Americandelivery: "US domestic shipping",//已发货配送单
        Signedfor:"Delivered packinglist",//已签收
        
        
      },
  },
  // 注册成功
  {
    path: '/registeredSuccess',
    name: 'registeredSuccess',
    component: () => import('../views/login/registeredSuccess.vue'),
  },
]

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const router = new VueRouter({
  routes,
  mode: 'history',
  base: '/',
})
let logPath = () => {
  let data = []
  routes.forEach((element1) => {
    data.push(element1.path)
    if (element1.children && element1.children.length != 0) {
      element1.children.forEach((element2) => {
        data.push(element2.path)
      })
    }
  })
  return data
}
let pcddd = logPath()
// console.log(pcddd);


let Changeroutertitle = (to) => {
  // 进行判断动态添加title
  let metacontent = document.querySelector('#metacontent')
  // 如果有标题就用标题 ，没有就直接Rakumart+用路由名
  if (to.meta.tagtitle) {
    document.title = to.meta.tagtitle
  } else {
    document.title = 'Rakumart-' + to.name
  }
  if (to.meta.tagdescription) {
    metacontent.content = to.meta.tagdescription
  } else {
    metacontent.content = ''
  }
  if (to.name == 'productDetails') {
    return
  }
  // 搜索页单独设置
  if (to.path == '/commoditySearch') {
    //  如果是搜索也就展示搜索标题
    var reg = /[a-z]/ //判断字符串是否为数字 ，判断正整数用/^[1-9]+[0-9]*]*$/

    if (
      to.query.type != 'imgSearch' &&
      reg.test(to.query.keyword.slice(0, 1))
    ) {
      var keyWordsstr =
        to.query.keyword.slice(0, 1).toUpperCase() + to.query.keyword.slice(1)
    } else {
      var keyWordsstr = to.query.keyword
    }
    document.title =
      'RAKUMART' + '-' + (to.query.imgUrl ? 'imagen' : keyWordsstr)
    metacontent.content = ''
  }
  // 订单详情7种情况
  if (to.path == '/orderDetails') {
    if (to.query.type == 'temporary') {
      document.title = to.meta.temporary
      metacontent.content = ''
      return
    }
    if (to.query.type == 'offer') {
      document.title = to.meta.offer
      metacontent.content = ''
      return
    }
    if (to.query.type == 'obligation') {
      document.title = to.meta.obligation
      metacontent.content = ''
      return
    }
    if (to.query.type == 'purchase') {
      document.title = to.meta.purchase
      metacontent.content = ''
      return
    }
    if (to.query.type == 'purchased') {
      document.title = to.meta.purchased
      metacontent.content = ''
      return
    }
    if (to.query.type == 'warehouse') {
      document.title = to.meta.warehouse
      metacontent.content = ''
      return
    }
    if (to.query.type == 'delete') {
      document.title = to.meta.delete
      metacontent.content = ''
      return
    }
    
    document.title = to.meta.tagtitle
    metacontent.content = ''
  }
  // 配送单
  if (to.path == '/deliveryDetails') {

    if (Number(to.query.status) == 20) {
          document.title = to.meta.Inthequotation
      metacontent.content = ''
      return
    }
    if (Number(to.query.status) == 30) {
          document.title = to.meta.obligation
      metacontent.content = ''
      return
    }
    if (Number(to.query.status) == 50) {
          document.title = to.meta.tobeshipped
      metacontent.content = ''
      return
    }
    if (Number(to.query.status) == 60) {
          document.title = to.meta.Shipped
      metacontent.content = ''
      return
    }
    if (Number(to.query.status) == 64) {
          document.title = to.meta.Americandelivery
      metacontent.content = ''
      return
    }
    if (Number(to.query.status) == 70) {
          document.title = to.meta.Signedfor
      metacontent.content = ''
      return
    }
     document.title = to.meta.tagtitle
    metacontent.content = ''
  }

  
}
let Databuryingpointmatching=(path)=> {
  if (path == '/register') {
    Vue.prototype.$nextTick(() => {
      if (__bl.sum) {
        __bl.sum('用户进入注册页面数量统计');
      }
  
    })
  }
}

let urlOrigin = location.origin
let openLogin = function(to) {
  // store.commit('getshowLoginDialog', true)//打开登录弹窗

  MessageBox.confirm(fanyi('是否前往登录?'), fanyi('提示'), {
    confirmButtonText: fanyi('登录'),
    cancelButtonText: fanyi('取消'),
  })
    .then(() => {
      location.href = urlOrigin + '/login'
    })
    .catch(() => {
      location.href = urlOrigin + '/'
    })
  store.commit('getactivePage', to.fullPath)
}
router.beforeEach((to, from, next) => {
  // 判断用户进入那个页面进行埋点统计 ，线上地址才进行统计
  if (window.location.origin == 'https://www.rakumart.us') {
    Databuryingpointmatching(to.path)
  }

  // 判断是否有token
  if (localStorage.getItem('user_token')) {
    // 没有登录信息时候去获取用户信息
    if (store.state.userInfo == null) {
      Vue.prototype.$api.EuropegetUserInfo().then((res) => {
        // 登录失效
        if (res.code != 0) {
          store.commit('userData', null)
          localStorage.removeItem('user_token')
          // 有需要验证的用户中心
          if (to.matched.some((record) => record.meta.requireAuth)) {
            // next({
            //   path: '/login',
            // })
            next()
            openLogin(to)
          }
          Message.error(res.msg)
          return
        } else {
          return store.commit('userData', res.data) // 用户信息存储
        }c
      })
    }
  }
  if (to.name != 'login') {
    store.commit('getactivePage', to.fullPath)
  }

  if (to.matched.some((record) => record.meta.requireAuth)) {
    // 判断该路由是否需要登录权限

    if (localStorage.getItem('user_token')) {
      next()
    } else {
      store.commit('getactivePage', to.fullPath)
      // next({
      //   path: '/login',
      // })
      next()
      openLogin(to)
    }
  } else {
    next()
  }
  // 判断路由动态添加titie
  Changeroutertitle(to)
})

router.afterEach((to, from, next) => {
  window.scrollTo(0, 0)
})
export default router
